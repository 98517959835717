<template>
    <v-form
        ref="editForm"
        v-model="valid"
        @submit="save"
        @submit.prevent>
        <v-card>
            <v-toolbar color="primary" dark>
                <v-spacer />
                <v-toolbar-title>User Settings</v-toolbar-title>
                <v-spacer />
            </v-toolbar>
            <v-card-text class="my-2">
                <app-autocomplete
                    v-model="currentUserTimeZoneId"
                    :schema="timeZoneSchema"
                    :is-add-disabled="true" />
            </v-card-text>

            <v-card-text class="my-2">
                <v-autocomplete
                    v-model="currentUserCultureId"
                    :items="cultures"
                    item-value="id"
                    item-text="label" />
            </v-card-text>
            <v-divider />
            <v-card-actions class="mx-1 py-4">
                <v-spacer />
                <app-button :text="'Cancel'" :outlined="true" :click="save" />
                <app-button :text="'Save'" :click="save" />
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script>
import axios from "axios";
import securityService from "@/services/securityService";

export default {
    components: {
        appAutocomplete: () => import("@/components/AppAutocomplete"),
        appButton: () => import("@/components/AppButton"),
    },
    props: {
        value: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            valid: true,
            isSaving: false,
            currentUserTimeZoneId: null,
            currentUserCultureId: null,
            timeZoneSchema: null,
            cultureSchema: null,
            cultures: [],
            user: null,
        };
    },

    async mounted() {
        this.timeZoneSchema = await this.getSchema("TimeZone");
        this.cultureSchema = await this.getSchema("Culture");
        this.user = await securityService.getCurrentUser();
        let settings = (await axios.get("/api/UserSettings/GetUserSettings/" + this.user.id)).data;
        this.currentUserTimeZoneId = settings.timeZoneId;
        this.currentUserCultureId = settings.cultureId;
        this.cultures = (await axios.get("/api/UserSettings/GetCultureIdLabels")).data;
    },

    methods: {
        async save() {
            if (this.isSaving) {
                return;
            }
            this.isSaving = true;

            let settings = {
                userId: this.user.id,
                timeZoneId: this.currentUserTimeZoneId,
                cultureId: this.currentUserCultureId,
            };

            try {
                let response = await axios.put("/api/UserSettings/SetUserSettings", settings);

                this.taskItem = response.data;
                this.$emit("input", this.taskItem);
                this.$emit("update", this.taskItem);
            } catch (error) {
                this.handleError(error);
            }
            this.isSaving = false;
            this.$emit("close");
        },

        cancel() {
            this.$emit("close");
            this.$emit("cancel");
        },
    },
};
</script>

<style scoped>
    .large-font > input {
        font-size: 20px;
    }
</style>
